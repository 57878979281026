import { getEnvOverride } from './overrides';
import { isNative } from 'src/features/native';

/**
 * Sampling rate for production environments.
 */
const prodSampleRate = 10.0;

export interface ConfigType {
    accessApi: string;
    api: string;
    appVersion: string | undefined;
    documentTitle: string;
    nlpApi: string;
    environmentName: string;
    i18nVersion: string;
    gaussApi: string;
    tradingApi: string;
    tracesSampleRate: number;
    oneSignalAppId: string;
    backtestSettingsSchemaVersion: number;
    quantApi: string;
    chatbotApi: string;

    /**
     * URI of the legacy locates deployment. When specified, UI uses it instead of platform-ws.
     */
    legacyLocatesApi?: string;
    locatesApi?: string;

    /**
     * FusionAuth configuration parameters
     */
    fusionAuthUrl: string;
    fusionAuthTenantId: string;
    fusionAuthApplicationId: string;
    tableProxy: string;

    /**
     * Launch Darkly Config
     */
    launchDarklyClientSideId: string;
    newQuantApi: string;
}

/**
 *
 * @param env the name of the environment; must be a valid API environment
 * @returns partial configuration object
 */
const shared = (env: string) =>
    ({
        /**
         * APIS (env-driven)
         */
        accessApi: `https://access-ws.${env}.thinkalpha.io`,
        api: `https://api.${env}.thinkalpha.io`,
        documentTitle: 'DEV THINKalpha',
        gaussApi: `https://gauss-ws.${env}.thinkalpha.io`,
        chatbotApi: `https://alpha-chatbot-api.${env}.thinkalpha.io`,
        tradingApi: `https://trading-ws.${env}.thinkalpha.io`,
        nlpApi: `https://nlp.${env}.thinkalpha.io`,
        environmentName: `${env}`,
        /**
         * VARS (base, expect overrides)
         */
        tracesSampleRate: 1.0,
        appVersion: undefined,
        oneSignalAppId: '',
        quantApi: `https://quant-ng-api.${env}.thinkalpha.io`,
        backtestSettingsSchemaVersion: 1,
        locatesApi: `https://locates-ws.${env}.thinkalpha.io`,
        legacyLocatesApi: undefined, // only available in QA and above,
        fusionAuthUrl: 'https://login.dev.thinkalpha.io/',
        fusionAuthTenantId: '876cf895-b361-4e57-a475-8568d483991d',
        fusionAuthApplicationId: 'fafbb3c2-528c-4802-824d-d64c7d9bf44a',
        tableProxy: `https://table-ws.${env}.thinkalpha.io`,
        launchDarklyClientSideId: '660c29bfb2e0e70fe6409c86',
        newQuantApi: `https://quant-ws.${env}.thinkalpha.io`,
    }) satisfies Partial<ConfigType>;

type EnvStr = 'prod' | 'prod-alpha' | 'dev' | 'qa' | 'uat';

export const getHrefEnv = (): EnvStr | undefined => {
    if (isNative) return undefined;

    // ToDo: Get askalpha spun out entirely from app config
    if (location.host === 'askalpha.io' || location.host === 'www.askalpha.io') {
        return 'prod';
    }

    if (location.host.endsWith('uat.thinkalpha.io')) return 'uat';
    if (location.host.endsWith('qa.thhinkalpha.io')) return 'qa';
    if (location.host.endsWith('prod-alpha.thinkalpha.io')) return 'prod-alpha';
    if (location.host.endsWith('dev.thinkalpha.io')) return 'dev';
    if (location.host.endsWith('prod.thinkalpha.io')) return 'prod';
    if (location.host.endsWith('platform.thinkalpha.io')) return 'prod';

    return undefined;
};

declare global {
    const APP_CONFIG_NAME: string; // TODO: deprecate; use "environment" terminology
    const APP_VERSION: string;

    interface Window {
        appConfig: ConfigType;
    }
}

let appConfig: ConfigType & { commit?: string };
try {
    const envOverride = getEnvOverride();

    // if (envOverride) {
    //     logger.info({ message: `Overriding env config from \`${APP_CONFIG_NAME}\` to \`${envOverride}\`` });
    // }

    const ENV_TO_USE = envOverride || APP_CONFIG_NAME;

    if (ENV_TO_USE === 'prod') {
        appConfig = {
            ...shared('prod'),
            documentTitle: 'THINKalpha Platform',
            tracesSampleRate: prodSampleRate,
            legacyLocatesApi: 'wss://locates.thinkalpha.io',
            oneSignalAppId: 'd6b8dc34-ce45-4542-b216-20fc1e7c1cc9',
            fusionAuthUrl: 'https://login.prod.thinkalpha.io',
            i18nVersion: 'prod',
            fusionAuthTenantId: 'd8272274-b48e-4ffc-9016-3677dc518501',
            fusionAuthApplicationId: '583d8656-e8c3-46fd-963f-d460a43f2a55',
            launchDarklyClientSideId: '660c29bfb2e0e70fe6409c87',
        };
    } else if (ENV_TO_USE === 'prod-alpha') {
        appConfig = {
            ...shared('prod-alpha'),
            documentTitle: 'THINKalpha Platform',
            tracesSampleRate: prodSampleRate,
            i18nVersion: 'prod',
            legacyLocatesApi: 'wss://locates.thinkalpha.io',
            oneSignalAppId: 'd6b8dc34-ce45-4542-b216-20fc1e7c1cc9',
            fusionAuthUrl: 'https://login.prod-alpha.thinkalpha.io',
            fusionAuthTenantId: '8d60dc83-8ec7-4efa-9757-2d2b55d19079',
            fusionAuthApplicationId: '3bb96e65-0bcd-485e-88b3-588f6f7577ce',
            launchDarklyClientSideId: '660c29bfb2e0e70fe6409c87',
        };
    } else if (ENV_TO_USE === 'dev') {
        appConfig = {
            ...shared('dev'),
            i18nVersion: 'latest',
            legacyLocatesApi: 'wss://locates-and-preborrows-app.dev.ny.thinkalpha.io',
            oneSignalAppId: `195612b8-82eb-484e-b79a-88f19d31d3a0`,
            // fusionAuthUrl: 'https://login.dev.thinkalpha.io',
            // fusionAuthTenantId: '876cf895-b361-4e57-a475-8568d483991d',
            // fusionAuthApplicationId: 'fafbb3c2-528c-4802-824d-d64c7d9bf44a',
            fusionAuthUrl: 'https://keycloak-lxc.dev.thinkalpha.io',
            fusionAuthTenantId: 'realms/thinkalpha',
            fusionAuthApplicationId: 'platform-ui',
            launchDarklyClientSideId: '660c29bfb2e0e70fe6409c86',
        };
    } else if (ENV_TO_USE === 'qa') {
        appConfig = {
            ...shared('qa'),
            i18nVersion: 'qa',
            documentTitle: 'QA THINKalpha',
            // legacyLocatesApi: 'wss://qalocates.thinkalpha.io',
            oneSignalAppId: 'd896a86c-a767-4e69-b502-e22b281f3a03',
            fusionAuthUrl: 'https://login.qa.thinkalpha.io',
            fusionAuthTenantId: '9f750007-6387-4be0-bc77-aa6f7b6ca3e3',
            fusionAuthApplicationId: '9bf2770a-cf32-4db8-8306-be024128c2ea',
            launchDarklyClientSideId: '660c436987af650fef869be1',
        };
    } else if (ENV_TO_USE === 'uat') {
        appConfig = {
            ...shared('uat'),
            i18nVersion: 'uat',
            documentTitle: 'UAT THINKalpha',
            tracesSampleRate: prodSampleRate / 2,
            legacyLocatesApi: 'wss://uatlocates.thinkalpha.io',
            oneSignalAppId: '0a5f5618-5dc6-4ecd-aa5c-c716ac5f9959',
            fusionAuthUrl: 'https://login.uat.thinkalpha.io',
            fusionAuthTenantId: 'a88da876-23be-426d-b5f4-2c7670a149d9',
            fusionAuthApplicationId: 'af3704b2-bee3-41f6-8dc6-208d56dbb413',
            launchDarklyClientSideId: '660c43746ca8d50f6f275d95',
        };
    } else {
        appConfig = {
            ...shared('dev'),
            i18nVersion: 'latest',
            appVersion: 'local',
            environmentName: 'local',
            oneSignalAppId: '73096378-e9ee-4c8b-96ce-15a618bdd03b',
            // fusionAuthUrl: 'https://login.dev.thinkalpha.io',
            // fusionAuthTenantId: '876cf895-b361-4e57-a475-8568d483991d',
            // fusionAuthApplicationId: 'fafbb3c2-528c-4802-824d-d64c7d9bf44a',
            fusionAuthUrl: 'https://keycloak-lxc.dev.thinkalpha.io',
            fusionAuthTenantId: 'realms/thinkalpha',
            fusionAuthApplicationId: 'platform-ui',
            launchDarklyClientSideId: '660c29bfb2e0e70fe6409c86',
        };

        if (import.meta.env.VITE_API_URL) {
            appConfig.api = import.meta.env.VITE_API_URL; // 'https://localhost:3000';
        }
        if (import.meta.env.VITE_TRADING_API_URL) {
            appConfig.tradingApi = import.meta.env.VITE_TRADING_API_URL; // 'https://localhost:3001';
        }
        if (import.meta.env.VITE_ACCESS_API_URL) {
            appConfig.accessApi = import.meta.env.VITE_ACCESS_API_URL; // 'https://localhost:3002';
        }
        if (import.meta.env.VITE_QUANT_API_URL) {
            appConfig.quantApi = import.meta.env.VITE_QUANT_API_URL; // 'https://localhost:3003';
        }
    }

    const env = getHrefEnv();

    if (env && env !== APP_CONFIG_NAME) {
        // TODO: Normalize href usage into overall configuration workflow
        // logger.info({
        //     message: `Using ${APP_CONFIG_NAME} config, but not on .${APP_CONFIG_NAME}. href; using "${env}" as current environment`,
        // });
        appConfig = {
            ...appConfig,
            ...shared(env),
        };
    }
} catch (e) {
    if (e instanceof ReferenceError) {
        // logger.error({
        //     message: 'Failed to identify the app config name! Defaulting to dev.',
        // });
        appConfig = {
            ...shared('dev'),
            i18nVersion: 'latest',
        };
    } else {
        throw e;
    }
}

if (typeof APP_VERSION !== 'undefined') {
    appConfig.appVersion = APP_VERSION;
} else {
    // logger.warn({ message: 'Missing app version constant' });
}

if (typeof window !== 'undefined') {
    window.appConfig = appConfig;
}

export { appConfig };
appConfig.commit = '7b73d478ee754b4f243644bda88bfdf91a1bc7be';
