import { appConfig } from './appConfig';
import { injectable } from 'inversify';
import { isNative } from 'src/features/native';
import type { ConfigService } from 'src/ioc/types/ConfigService';
import { z } from 'zod';

const FusionAuthConfigSchema = z.object({
    fusionAuthURL: z.string(),
    userInfoURL: z.string(),
    logoutURL: z.string(),
    tenantID: z.string(),
    applicationID: z.string(),
    callbackURL: z.string(),
    loggedOutURL: z.string(),
    tokenRevocationURL: z.string(),
});

@injectable()
export class ConfigServiceImpl implements ConfigService {
    #appConfig = appConfig;

    get launchDarklyConfig(): ConfigService.LaunchDarklyConfig {
        return {
            clientSideId: this.#appConfig.launchDarklyClientSideId,
        };
    }

    get oAuthConfig(): ConfigService.OAuthConfig {
        const fusionAuthURL = this.#appConfig.fusionAuthUrl;
        const tenantID = this.#appConfig.fusionAuthTenantId;
        const applicationID = this.#appConfig.fusionAuthApplicationId;
        // const userInfoURL = `${fusionAuthURL}/oauth2/userinfo`;
        const userInfoURL = `${fusionAuthURL}/realms/thinkalpha/protocol/openid-connect/userinfo`;
        const logoutURL = `${fusionAuthURL}/realms/thinkalpha/protocol/openid-connect/logout`;
        const tokenRevocationURL = `${fusionAuthURL}/realms/thinkalpha/protocol/openid-connect/revoke`;

        if (isNative) {
            return {
                fusionAuthURL,
                userInfoURL,
                logoutURL,
                tokenRevocationURL,
                tenantID,
                applicationID,
                callbackURL: 'https://dev.thinkalpha.io/oauth2/callback',
                loggedOutURL: 'https://dev.thinkalpha.io/loggedout',
            };
        }

        const override = localStorage.getItem('configService.oAuthConfig.override');
        if (override) {
            try {
                const overrideValue = FusionAuthConfigSchema.parse(JSON.parse(override));
                // eslint-disable-next-line no-console
                console.log('Using override value for oAuthConfig', override);
                return overrideValue;
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error('Could not parse localStorage override for oAuthConfig', e);
            }
        }

        return {
            fusionAuthURL,
            userInfoURL,
            logoutURL,
            tokenRevocationURL,
            tenantID,
            applicationID,
            callbackURL: `${window.location.origin}/oauth2/callback`,
            loggedOutURL: `${window.location.origin}/loggedout`,
        };
    }

    get environmentName() {
        return this.#appConfig.environmentName;
    }

    get commit() {
        return this.#appConfig.commit;
    }

    get documentTitle() {
        return this.#appConfig.documentTitle;
    }
}
